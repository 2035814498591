.notification-container {
  user-select: none;
  position: fixed;
  right: 1rem;
  top: 1rem;
  z-index:1;
}

.notification-fadeout {
  overflow:hidden;
  box-sizing: border-box;
  animation-duration: 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;  
  animation-name: notification-hide;
}

@keyframes notification-hide {
  0% {opacity: 1;}
  90% {opacity: 1;}
  100% {opacity: 0;}
}

