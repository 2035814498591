.timer-container {
  position: fixed;
  top:0px;
  left:0px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
  user-select: none;
}

.timer-card{
  border-radius:  0 0 0.25rem 0.25rem;
}