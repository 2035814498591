.cursor {
  position: fixed; 
  z-index: 2;
  left: 50%;
  top: 50%;
  width: 8px; 
  height: 8px; 
  margin-left: -4px; 
  margin-top: -4px; 
  border-radius: 100%; 
  border: 4px solid white;
  box-sizing: border-box;
  transition: all 0.2s ease;
}

.cursor-hover {
  width: 18px; 
  height: 18px; 
  margin-left: -9px; 
  margin-top: -9px; 
  border: 3px solid white;
}

.is-text-centered {
  text-align: center;
}

.selection{
  user-select: none;
  position: fixed;
  z-index: 1;
  margin-left: 4px;
  margin-top: 4px;
  top:50%;
  left:50%;
  overflow: hidden;
  transform: scale(0);
  transform-origin: 0 0;
  box-sizing: border-box;
  transition: all 0.2s ease;
}

.selection-hover {
  transform: scale(1);
}

.keyboard-key {
  border: 1px solid white;
  width: 2rem;
  height: 2rem;
  border-radius: 0.25rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  display: inline-block;
  margin-left: 0.5rem;
}

.bottom-right-hint {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index:1;
}