
.inspect-three-container {
  position: fixed;
  z-index:1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  filter: sepia(20%) brightness(1.5);
}

.threedialog {
  position: fixed;
  width: 100%;
  text-align: center;
  top: 2rem;
  z-index: 2;
}