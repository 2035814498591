
.mainContainer{
  position: fixed;
  top:0; right: 0;
  bottom: 0; left:0;
  display: flex;
  overflow: auto;
  padding: 2rem 0;
  z-index: 2;
}


.login-right{
  display: flex;
  flex-direction: column;
  z-index:2;
  height: 100%;
}

.checkbox-list {
  list-style-type: "\2611";
  padding: 1rem 2rem;
  /*padding: unset !important;
  margin: unset !important;*/
}

.checkbox-list > li {
  padding: 0 1rem;
}

.login-center {
  flex-shrink: 1;
  margin-left: 1rem;
  margin-right: 1rem;

  width: 100vw;
  max-width: 800px;
  min-height: 100%;
  
  display: flex;
  flex-direction: column;
}


.loginCard {
  max-height: 55vh;
  max-height: calc(100vh-15rem);
  overflow: auto;
}

.horizontal-centered {
  display: flex;
  width: 100%;
  justify-content: center;
}