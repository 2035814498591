.dialog {
  user-select: none;
}

.page-control {
  display: flex;
  justify-content: space-between;
}

.page{
  background: #e4decb;
	padding: 1rem;
  width: 50%;
  height: 100%;
	flex-direction: column;
	color: #2e2c26;
	font-size: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
  
  transform-style: preserve-3d;
}

.page-container {
	margin: 1rem;
  position: relative;
  min-width: 24rem;
	min-height: 18rem;
  perspective: 1000px;
  transform-style: preserve-3d;
}

.page-left{
  position: absolute;
  left: 0;
  
  box-shadow: inset -15px 0 15px -15px rgb(0 0 0 / 40%)
}

.page-right{
  position: absolute;
  left: 50%;
  box-shadow: inset 15px 0 15px -15px rgb(0 0 0 / 40%)
}

.page-number {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
	color: #2e2c26;
  font-size: 1rem;
}

.page-current{
  position: absolute;
  left: 50%;
  transform-origin: 0 0;
  
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;  
}

.page-front {
  position:absolute;
  padding: 1rem;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: #e4decb;
  backface-visibility: hidden;
	flex-direction: column;
	display: flex;
	justify-content: center;
	align-items: center;
  transform-style: preserve-3d;
  box-shadow: inset 15px 0 15px -15px rgb(0 0 0 / 40%)
}

.page-back {
  position:absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: #e4decb;
  backface-visibility: hidden;
  transform: rotate3d(0,1,0, 180deg);
  transform-style: preserve-3d;
  box-shadow: inset -15px 0 15px -15px rgb(0 0 0 / 40%)
}

.scroll-forward {
  animation-name: scroll-forward;
}

.scroll-backward {
  animation-name: scroll-backward;
}

@keyframes scroll-forward {
  from {transform: rotate3d(0, 1, 0, 0deg);}
  to {transform: rotate3d(0, 1, 0, -180deg);}
}

@keyframes scroll-backward {
  from {transform: rotate3d(0, 1, 0, -180deg);}
  to {transform: rotate3d(0, 1, 0, 0deg);}
}

.errorTable > table, .errorTable > table > tr,  .errorTable > table > tr > td {
  border: 1px solid black;
  border-collapse: collapse;
  
  font-size: 0.8rem;
}