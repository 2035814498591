
#three-container {
  filter: sepia(20%) brightness(1.5);
  position: fixed;
  left: 0;
  top:0;
  width:100%;
  height: 100%;
  z-index: -1;
}

.button .is-priamry {
  background-color: #269463 !important;
}