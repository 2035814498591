.dialog {
  user-select: none;
}

.page-control {
  display: flex;
  justify-content: space-between;
}

.modal-background > canvas {
  z-index:unset !important;
}

