
.mainContainer{
  position: fixed;
  top:0; right: 0;
  bottom: 0; left:0;
  display: flex;
  overflow: auto;
  padding: 2rem 0;
  z-index: 2;
}

.playerContainer {
  z-index: 2;
  flex-shrink: 0;
  margin-left:2rem;
}

.playerCard {
  margin-bottom: 2rem;
}

.lobby-right{
  display: flex;
  flex-direction: column;
  z-index:2;
  height: 100%;
}

.lobby-center {
  flex-shrink: 1;
  margin-left: 2rem;
  margin-right: 2rem;
  background-image: url("./images/escape_party.svg");
  background-position: center 0;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100vw;
  max-width: 600px;
}

.startGameButton {
  width: 18rem;
}

.connectionShare {
  width: 18rem;
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  opacity: 0.3;
  z-index: -1;
  transition: opacity .3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  opacity: 1;
  z-index: 10;
  flex-direction: column;
}



.loader {
  height: 80px;
  width: 80px;
}

#loaderSpinner{
  animation: spinAround 1.5s infinite linear;
}