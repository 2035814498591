.player-representation-container {
    position: fixed;
    z-index: 1;
    left:0;
    top:0;
    right:0;
    bottom:  0;
    overflow: hidden;
    pointer-events: none
}

.player_name {
	position: fixed;
	overflow: hidden;
	width: 200px;
	text-align: center;
	margin-left: -100px;
    text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
}