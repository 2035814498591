.iframe-container {
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  height: 50rem; 
  max-height: 60vh; 
  position: relative;
}

.logo-image{
  background-image: url("../images/escape_party.svg");
  background-position: center 0;
  background-size: contain;
  background-repeat: no-repeat;
  flex-shrink: 0;
  width: 100%;
  height: 15rem;
  max-height: 30vh;
}

.iframe-container > video {
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.iframe {
  position: absolute; left:0; top:0px; width:100%; height: 100%;
}

.feature-icon {
  border-radius: 100%;
  border: 1px solid rgb(38, 148, 99);
  color: rgb(38, 148, 99);
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}