.language-selector-container-fixed {
  position: fixed;
  top:0px;
  left:0px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  user-select: none;
}

.language-selector-container-inline {
  position: relative;
  top:0px;
  left:0px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
  user-select: none;
}

.language-selector-card{
  border-radius:  0 0 0.25rem 0.25rem;
}

.flag-image {
  height: 24px;
  display: inline-block;
  margin-right: 1rem;
}

.language-selector-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.language-selector-item:hover {
  background-color: #f5f5f5;
}